.navbar {
  height: 5rem;
  background-color: rgb(90, 90, 90);
}

.logo {
  display: flex;
  max-height: 4.25rem;
}

.logo img {
  height: 1.7rem;
  top: -.8rem;
  left: -2.9rem;
  position: relative;
}

.logo h1 {
  margin: 0 auto;
  font-size: 65px;
  font-family: 'Poppins', sans-serif;
}



@media only screen and (min-width: 992px){
nav p {
    display: none;
}
}

.closeSidenav {
  position: relative;
  top: -7.5rem;
  right: -18rem;
  cursor: pointer;
}