.App-outer-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

.App-inner-container {
  flex-grow: 1;
}

.App-layer {
  display: flex;
}